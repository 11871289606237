export const toTitleCase = string => {
  if (string !== "" && string !== null) {
    let return_string = ""
    string.split(" ").forEach(s => {
      if (s === "CVS" || s === "HEB" || s === "H-E-B" || s === "BVA") {
        if (s === "HEB") {
          s = "H-E-B"
        }
        return_string += s + " "
      } else {
        return_string += s[0] + s.substring(1).toLowerCase() + " "
      }
    })
    return return_string.trim()
  }
  return string
}

export const toKebobCase = string => {
  if (string !== "" && string !== null) {
    return string.replace(/[^A-Z0-9]+/ig, "-").toLowerCase().trim()
  }
}

export const pharmacyFilter = () => {

}