import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { toTitleCase, toKebobCase } from "../helpers/stringHelpers"
import "../css/PharmacyCard.css"

const PharmacyCard = props => {

  let pharmacy = toTitleCase(props.pharmacy)
  let id = toKebobCase(props.pharmacy)
  // Class for the div that hold price
  // let priceClass = props.price[0] === "$"
  //   ? "card-price"
  //   : "card-price-message"

  return (
    <div className="card">
      <div className="card-header" id={`${id}-header`}>
        <div
          className="row card-row"
          data-toggle="collapse"
          data-target={`#${id}`}
          aria-expanded="false"
          aria-controls={pharmacy}
        >
          <div className="col-7">
            <div className="caret">
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
            <h2 className="card-h2">
              <span className="card-title">{pharmacy}</span>
              <span className="card-sub-title">View Locations</span>
            </h2>
          </div>
          <div className="col-5">
            {/* <div className={priceClass}>{props.price}</div> */}
            <div className="card-price">{props.price}</div>
          </div>
        </div>
      </div>

      <div
        id={`${id}`}
        className="collapse"
        aria-labelledby={`${id}-header`}
        data-parent="#pricingAccordion"
      >
        <div className="card-body">
          <table className="table drug-pricing-table">
            <tbody>{props.children}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PharmacyCard
