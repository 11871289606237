import { UPDATE_AUTOCOMPLETE } from "./types"
import { updateQuery } from "./searchActions"

export const fetchSearchChoices = val => {
  return dispatch => {
    let term = encodeURIComponent(val)
    dispatch(updateQuery(decodeURI(term)))
    // Force lowercase to avoid errors
    val = val.toLowerCase()

    // Only look for suggestions if search term is 3 + chars
    if (val.length > 2) {
      fetch(process.env.REACT_APP_AUTOCOMPLETE_URL + term, {
          headers: {
            Authorization: "Bearer dOsHkFMHTeo99GWqY0mY"
          },
          method: "get"
        })
        .then(response => response.json())
        .then(data => {
          dispatch(updateAutoComplete(data))
        })
    }
  }
}

export const updateAutoComplete = val => ({
  type: UPDATE_AUTOCOMPLETE,
  payload: val
})