import { PRICING_DATA_FETCH_SUCCESS } from "../actions/types"

export default (state = {}, { type, payload }) => {
  switch (type) {
    case PRICING_DATA_FETCH_SUCCESS:
      //state = { ...state, pricingReturn: payload }
      return payload
    default:
      return state
  }
}
