import { UPDATE_AUTOCOMPLETE } from "../actions/types"

export default (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_AUTOCOMPLETE:
      state = { ...state, search_choices: payload }
      return payload
    default:
      return state
  }
}
