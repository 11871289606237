import React from "react"

const PricingTable = props => {
  return (
    <table className="table table-striped drug-pricing-table">
      <thead>
        <tr>
          <th>Pharmacy</th>
          <th className="text-center">Price with Coupon</th>
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </table>
  )
}

export default PricingTable
