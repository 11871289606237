import React from "react"

const CustomQuantity = props => {
  let customQunatity = React.createRef()
  return (
    <div>
      <div className="dropdown-divider" key="divider" />
      <p className="customAmount" key="customP">
        Enter custom amount:
      </p>
      <div
        style={{ textAlign: "center", padding: "5px" }}
        key="custom-div"
        className="input-group"
      >
        <div className="input-group-prepend">
          <input
            id="customQty"
            className="form-control"
            placeholder="Quantity"
            ref={customQunatity}
            type="number"
          />
          <button
            type="button"
            className="btn btn-success mb-2"
            onClick={() =>
              props.updateSearch("qty", customQunatity.current.value)
            }
          >
            Update
          </button>
        </div>
      </div>
    </div>
  )
}

export default CustomQuantity
