import React, { Component } from "react"
import { connect } from "react-redux"
import NavItem from "./NavItem"
import Navbar from "./NavBar"
import ZipResultSearch from "./ZipResultSearch"
import PricingTable from "./PricingTable"
import PharmacyRow from "./PharmacyRow"
import { fetchPricingData } from "../actions/searchActions"
import { toTitleCase } from "../helpers/stringHelpers"
//import { toTitleCase } from "../helpers/generalHelpers"

// Accordion components
import PharmacyCard from "./PharmacyCard"
import PricingAccordion from "./PricingAccordion"

import "../css/Results.css"

class Results extends Component {
  /**
   * Loops through array of related items
   * and forms an array of html buttons with
   * proper onClick funtions
   */
  makeButtonList = (section, type) => {
    if (section !== null || section.length > 0) {
      return section.map((target, index) => (
        <button
          className="dropdown-item"
          key={index}
          onClick={() =>
            this.updateSearch(type, type === "name" ? target.name : target)
          }
        >
          <span className="dropdownOption">
            {/* if the type is strength, we need to clean it */}
            {type === "name"
              ? target.name
              : type === "strength"
              ? this.cleanDisplayStrength(target)
              : target}
          </span>
          {type === "name" && (
            <span className="drugTypeOption"> {target.type}</span>
          )}
        </button>
      ))
    }
    if (section === "form") return ""
    return []
  }

  /**
   * properly formats the display strength in the return object
   */
  cleanDisplayStrength = data => {
    let display = data
    let decimal
    let space_count = display.split(" ").length - 1
    // make sure that there is just one space
    if (space_count === 1) {
      let med_array = display.split(" ")
      decimal = med_array[0].match(/(\.\d+)/g)

      // If it is actually a decimal
      if (decimal !== null) {
        // if the is more than one decimal place
        if (decimal[0].length - 1 === 1) {
          // Check that it's a zero before removing
          if (decimal[0][1] === "0") {
            med_array[0] = Math.floor(med_array[0]).toString()
          }
        }
      }

      // Remove the space and convert to lowercase
      display = med_array[0] + med_array[1]
    } else if (space_count === 0) {
      // Check for a decimal and remove
      decimal = display.match(/(\.\d+)/g)
      if (decimal !== null) {
        // if the is more than one decimal place
        if (decimal[0].length - 1 === 1) {
          if (decimal[0][1] === 0) {
            display = display.replace(/(\.\d+)/g, "")
          }
        }
      }
    }
    return display.toLowerCase()
  }

  /**
   * Forms an update request object based on what is being updated
   */
  updateSearch = (section, update) => {
    let quantity = ""
    let regx = this.props.displayItems.displayQuantity.match(
      /[+-]?\d+(\.\d+)?/g
    )
    if (regx !== null) {
      quantity = regx[0]
    }

    let search = {
      pricingRequest: {
        location: {
          zipCode: this.props.location.zipCode
        },
        drugInfo: {
          name: this.props.displayItems.displayDrug.name,
          form: this.props.displayItems.displayForm,
          strength: this.props.displayItems.displayStrength,
          quantity: quantity
        },
        type: {
          section: ""
        }
      }
    }

    switch (section) {
      case "name":
        search.pricingRequest.drugInfo.name = update
        break
      case "strength":
        search.pricingRequest.drugInfo.strength = update
        break
      case "form":
        search.pricingRequest.drugInfo.form = update
        break
      case "qty":
        search.pricingRequest.drugInfo.quantity = update
        break
      case "zip":
        section = "qty" // set section to name so it get's set properly
        search.pricingRequest.location.zipCode = update
        break
      default:
        break
    }

    search.pricingRequest.type.section = section
    this.props.fetchPricingData(search, this.props.search_url)
  }

  /**
   * Sorts through pharmacies and makes an array
   */
  sortPharmacies = () => {
    let sorted = {}
    let name_array = ["H-E-B Pharmacy"]
    let price_messages = {}
    this.props.pharmacyPrices.forEach(item => {
      let name = toTitleCase(item.pharmacyName)
      if (name_array.length > 0) {
        // Loop through the current list of names
        if (!name_array.includes(name)) {
          // First test some regex to make sure it isn't weird.
          name_array.forEach(item => {
            let re = new RegExp(`(.*?)${item}(.*?)`, "i")
            if (name.match(re) !== null) {
              name = name.match(re)[0]
            } else {
              name_array.push(name)
            }
          })
        }
      }

      if (name in sorted) {
        sorted[name].push(item)
      } else {
        // If there is a message, we want to add the pharmacy ot the end
        // so we add it to a separate array temporarily
        // if (item.brand_pricing_message) {
        //   if (name in price_messages) {
        //     price_messages[name].push(item)
        //   } else {
        //     price_messages[name] = [item]
        //   }
        // } else {
          sorted[name] = [item]
        // }
      }
    })
    
    // If we have anything in the messages object, we need to move those to the
    // end of the sorted object
    return sorted//Object.assign(sorted, price_messages)
  }

  /**
   * Loops through the sorted object of returns and builds the accordion component
   */
  buildAccordion = () => {
    let sorted = this.sortPharmacies()
    let pharmacies = Object.keys(sorted)
    let prices = Object.values(sorted)
    let accordion = []

    pharmacies.forEach((pharmacy, pharmacy_index) => {
      // Temporary array for current pharmacy
      let price_cards = []
      let current_price = prices[pharmacy_index][0].price//.brand_pricing_message 
        // ? prices[pharmacy_index][0].pricing_message 
        // : prices[pharmacy_index][0].price
      prices[pharmacy_index].forEach((price, price_index) => {
        // push all price information into list of pharmacy rows
        price_cards.push(
          <PharmacyRow
            accordion={this.props.accordion}
            key={price_index}
            pharmacy={price.pharmacyName}
            address1={price.address1}
            address2={price.address2}
            city={price.city}
            state={price.state}
            zip={price.zipCode}
          />
        )
      })

      // push pharmacy card to accordion array
      accordion.push(
        <PharmacyCard pharmacy={pharmacy} price={current_price} key={pharmacy_index}>
          {price_cards}
        </PharmacyCard>
      )
    })
    return accordion
  }

  /**
   * Build the results based on accordion variable
   */
  buildResults = () => {
    let rows
    if (this.props.accordion) {
      rows = this.buildAccordion()
      return (
        <PricingAccordion>{rows}</PricingAccordion>
      )
    }
    rows = this.props.pharmacyPrices.map((price, index) => (
      <PharmacyRow
        // message={price.brand_pricing_message}
        accordion={this.props.accordion}
        key={index}
        pharmacy={price.pharmacyName}
        address1={price.address1}
        address2={price.address2}
        city={price.city}
        state={price.state}
        zip={price.zipCode}
        price={price.price}//.brand_pricing_message ? price.pricing : price.price}
      />
    ))
    return (
      <PricingTable>{rows}</PricingTable>
    )
  }

  related_drugs = this.makeButtonList(
    this.props.relatedItems.relatedDrugs,
    "name"
  )
  related_strength = this.makeButtonList(
    this.props.relatedItems.relatedStrengths,
    "strength"
  )
  related_forms = this.makeButtonList(
    this.props.relatedItems.relatedForms,
    "form"
  )
  related_quantities = this.makeButtonList(
    this.props.relatedItems.relatedQuantities,
    "qty"
  )

  results = this.buildResults()

  render() {

    return (
      <div className="resultsWrapper">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="header-drug-name portal-color">
              {this.props.displayItems.displayDrug.name}
            </h1>
          </div>
          <div className="col-sm-12">
            <div className="portal-font">
              <Navbar title={"Prescription Settings:"}>
                <NavItem
                  section="drug"
                  display={this.props.displayItems.displayDrug}
                  related={this.related_drugs}
                />
                <NavItem
                  section="form"
                  display={this.props.displayItems.displayForm}
                  related={this.related_forms}
                />
                <NavItem
                  section="strength"
                  display={this.cleanDisplayStrength(
                    this.props.displayItems.displayStrength
                  )}
                  related={this.related_strength}
                />
                <NavItem
                  section="quantity"
                  display={this.props.displayItems.displayQuantity}
                  related={this.related_quantities}
                  updateSearch={this.updateSearch}
                />
              </Navbar>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <h2 className="drug-pricing-header">
              <span style={{ color: "#000" }}>
                Discounted Prices near zip code {this.props.location.zipCode}{" "}
                for:
              </span>
              <br />
              <strong>
                {this.props.displayItems.displayDrug.name}{" "}
                {this.props.displayItems.displayStrength}
              </strong>
              <br />
              {this.props.displayItems.displayQuantity}
            </h2>
          </div>
          <div className="col-sm-6">
            <ZipResultSearch updateSearch={this.updateSearch} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {this.results}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  location: state.pricingReturn.location,
  displayItems: state.pricingReturn.displayItems,
  relatedItems: state.pricingReturn.relatedItems,
  pharmacyPrices: state.pricingReturn.pharmacyPrices,
  search_url: state.search_url
  //collapse_pharmacies: state.collapse_pharmacies
})

export default connect(
  mapStateToProps,
  { fetchPricingData }
)(Results)
