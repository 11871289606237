import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';


export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <div className="container">
          <p>Something has gone wrong. Please submit your feedback so we can prevent this from hapening in the future.</p>
          <button
            type="button"
            className="btn btn-success mb-2" onClick={() => Sentry.showReportDialog()}>Report feedback
          </button>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}