import React from "react"
import ErrorMessage from "./ErrorMessage"
import TemporaryErrorMessage from "./TemporaryMessage"
import "../css/Search.css"

const SearchForm = ({ err, children, pbm, temporary_error }) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <h2 className="portal-color">
          Medication Pricing{" "}
          {pbm === "welldyne" && <span id="pbm">(via Welldyne)</span>}
        </h2>
        {err && <ErrorMessage message={err} />}
        {temporary_error && <TemporaryErrorMessage />}
        <div className="drug-search-form-wrapper">
          <form className="form-inline" id="search-form">
            {children}
          </form>
        </div>
      </div>
      <div className="col-sm-12">
        <div className="disclaimer">
          <p>
            * Medication prices vary by pharmacy and prescription and are
            subject to change over time. Ask your pharmacist for the actual
            discounted price.
          </p>
        </div>
      </div>
    </div>
  )
}

export default SearchForm
