import { UPDATE_SEARCH_ERROR } from "../actions/types"

export default (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_SEARCH_ERROR:
      //state = { ...state, search_error: payload }
      return payload
    default:
      return state
  }
}
