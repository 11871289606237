import { createStore, applyMiddleware } from "redux"
import rootReducer from "./reducers"
import thunk from "redux-thunk"

export const initialState = {
  query: "",
  zipcode: "",
  pricingReturn: null,
  search_error: "",
  loading: false,
  search_url: process.env.REACT_APP_SEARCH_URL,
  search_choices: [],
}

const middleware = [thunk]


const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware),
  // compose(
  //   applyMiddleware(...middleware),
  //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // )
)

export default store
