import React from "react"
import { connect } from "react-redux"
import { updateZipcode } from "../actions/searchActions"
import { keyPressHandler } from "../helpers/searchHelpers"

const ZipInput = ({ placeholder, updateZipcode, zipcode }) => {
  return (
    <input
      type="text"
      className="form-control mb-2 mr-sm-2"
      id="zip-search"
      placeholder={placeholder}
      onChange={e => updateZipcode(e.target.value.trim())}
      onKeyDown={e => keyPressHandler(e)}
      value={zipcode}
    />
  )
}

const mapStateToProps = state => ({
  zipcode: state.zipcode
})

export default connect(
  mapStateToProps,
  { updateZipcode }
)(ZipInput)
