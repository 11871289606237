import { UPDATE_QUERY } from "../actions/types"

export default (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_QUERY:
      //state = { ...state, query: payload }
      return payload
    default:
      return state
  }
}
