import React from "react"
import "../css/TemporaryMessage.css"

const TemporaryMessage = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-sm-10">
        <div className="temporary-message">
          <p>
            Pricing is temporarily unavailable while we are making updates.
            <br />
            Please visit your local pharmacy for current discount pricing.
          </p>
        </div>
      </div>
    </div>
  )
}

export default TemporaryMessage
