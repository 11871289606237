import React from "react"
import CustomQuantity from "./CustomQuantity"

const NavItem = props => {
  // Don't display if it is empty
  if (props.display === "") {
    return ""
  }

  let display_format = ""
  let dropdown_menu = ""
  let display = props.display

  // Set display format
  props.section === "drug"
    ? (display_format = (
        <span className="currentDisplay">
          {display.name + " "}
          <span className="drugType">{display.type}</span>
        </span>
      ))
    : (display_format = <span className="currentDisplay">{display}</span>)

  // Build dropdown menu
  if (props.related.length > 0) {
    dropdown_menu = (
      <div className="dropdown-menu" aria-labelledby="med-dropdown">
        {props.related}
        {props.section === "quantity" && <CustomQuantity />}
      </div>
    )
  }

  // If it's quantity make sure to add custom quantity
  if (props.section === "quantity") {
    dropdown_menu = (
      <div className="dropdown-menu" aria-labelledby="med-dropdown">
        {props.related}
        <CustomQuantity updateSearch={props.updateSearch} />
      </div>
    )
  }

  let li_class = "nav-item dropdown"
  let button_class = "nav-link dropdown-toggle btn-block"
  let button_is_disabled
  if (props.related.length === 0) {
    if (props.section === "quantity") {
      button_is_disabled = false
    } else {
      button_is_disabled = true
    }
  } else {
    button_is_disabled = false
  }

  if (button_is_disabled) button_class += " disabled"

  return (
    <li className={li_class}>
      <button
        className={button_class}
        id="med-dropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        {...(button_is_disabled ? { disabled: true } : {})}
      >
        {display_format}
      </button>
      {dropdown_menu}
    </li>
  )
}

export default NavItem
