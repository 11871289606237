import React from "react"
import Downshift from "downshift"
import { connect } from "react-redux"
import { updateQuery } from "../actions/searchActions"
import { fetchSearchChoices } from "../actions/autoCompleteActions"

class AutoComplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: ""
    }
    this.searchInput = React.createRef()
  }

  render() {
    return (
      <Downshift
        itemToString={item => (item ? item : "")}
        onStateChange={({ inputValue }) => {
          inputValue = this.searchInput.current.value.toLowerCase()
          this.setState({ inputValue })
        }}
        onChange={() => this.props.updateQuery(this.searchInput.current.value)}
        selectedItem={this.state.inputValue}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem
        }) => (
          <div className="autocomplete">
            <input
              autoCapitalize="off"
              {...getInputProps({
                className: "form-control mb-2",
                ref: this.searchInput,
                placeholder: "Enter your prescription",
                onChange: (e) => this.props.fetchSearchChoices(e.target.value.trim())
                }
              )}
            />

            {isOpen && this.props.search_choices.length >= 1 ? (
              <div className="autocomplete-items">
                {this.props.search_choices
                  .filter(i => !inputValue || i.indexOf(inputValue) !== -1)
                  .map((item, index) => (
                    <div
                      {...getItemProps({
                        key: item,
                        index,
                        item,
                        style: {
                          backgroundColor:
                            highlightedIndex === index ? "lightgray" : "white",
                          fontWeight: selectedItem === item ? "bold" : "normal"
                        }
                      })}
                    >
                      {item}
                    </div>
                  ))}
              </div>
            ) : null}
          </div>
        )}
      </Downshift>
    )
  }
}

const mapStateToProps = state => ({
  search_choices: state.search_choices
})

export default connect(
  mapStateToProps,
  { updateQuery, fetchSearchChoices }
)(AutoComplete)
