import { combineReducers } from "redux"
import queryReducer from "./queryReducer"
import autocompleteReducer from "./autocompleteReducer"
import zipcodeReducer from "./zipcodeReducer"
import pricingDataReducer from "./pricingDataReducer"
import loadingReducer from "./loadingReducer"
import searchErrorReducer from "./searchErrorReducer"
import searchURLReducer from "./searchURLReducer"

export default combineReducers({
  query: queryReducer,
  zipcode: zipcodeReducer,
  search_choices: autocompleteReducer,
  pricingReturn: pricingDataReducer,
  loading: loadingReducer,
  search_error: searchErrorReducer,
  search_url: searchURLReducer,
})
