import { UPDATE_LOADING } from "../actions/types"

export default (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_LOADING:
      //state = { ...state, loading: payload }
      return payload
    default:
      return state
  }
}
