import React from "react"
import { connect } from "react-redux"
import AutoComplete from "./AutoComplete"
import ZipInput from "./ZipInput"
import SearchForm from "./SearchForm"
import {
  fetchPricingData,
  updateQuery,
  updatePricingError,
  updateSearchURL,
} from "../actions/searchActions"
import { getZipCodeError } from "../helpers/searchHelpers"

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emptySearch: true,
      firstSearch: true,
      temporary_error: process.env.REACT_APP_TEMPORARY_ERROR === "true" ? true : false
    }
    if (this.props.drugname !== "" && this.props.drugname !== undefined) {
      if (!this.state.temporary_error) {
        this.getData(true)
      }
    }
  }

  componentDidUpdate() {
    if (this.props.search_error && !this.state.firstSearch) {
      this.setState({ firstSearch: true })
    }
  }

  render() {
    let isDisabled = this.state.temporary_error ? true : false
    return (
      <SearchForm err={this.props.search_error} pbm={this.props.pbm} temporary_error={this.state.temporary_error}>
        <AutoComplete />
        {this.state.firstSearch && <ZipInput placeholder="Zip Code" />}
        
        <button
          type="button"
          className="btn btn-success mb-2"
          id="result-btn"
          disabled={isDisabled}
          onClick={() => this.getData(false)}
        >
          Search
        </button>
        
      </SearchForm>
    )
  }

  searchParamIsNotValid = () => {
    if (this.props.query !== "") {
      if (!this.props.search_choices.includes(this.props.query)) {
        let err = `We couldn't find "${
          this.props.query
        }". Please select your medication from the dropdown list.`
        this.props.updatePricingError(err)
        return true
      }
    } else if (this.props.query === "") {
      this.props.updatePricingError("Please enter a medication")
      return true
    }
  }

  getData = (passed_var = false, reqBody = "") => {
    this.props.updatePricingError(false)
    if (!passed_var) {
      if (this.searchParamIsNotValid()) {
        return false
      }
    }

    let zipErr = getZipCodeError(this.props.zipcode)
    if (zipErr) {
      this.props.updatePricingError(zipErr)
      return false
    }

    this.setState({ firstSearch: false, emptySearch: false })
    // The request needs a body
    if (reqBody === "" || reqBody === undefined) {
      reqBody = {
        pricingRequest: {
          location: {
            zipCode: this.props.zipcode
          },
          drugInfo: {
            name: this.props.query,
            form: "",
            strength: "",
            quantity: ""
          },
          type: {
            section: "name"
          }
        }
      }
    }
    // Call function to get data
    this.props.fetchPricingData(reqBody, this.props.search_url)
  }
}

const mapStateToProps = state => ({
  query: state.query,
  zipcode: state.zipcode,
  search_error: state.search_error,
  search_url: state.search_url,
  search_choices: state.search_choices
})

export default connect(
  mapStateToProps,
  { fetchPricingData, updateQuery, updatePricingError, updateSearchURL }
)(Search)
