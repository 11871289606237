import React from "react"
import "../css/PricingAccordion.css"

const PricingAccordion = props => {
  return (
    <div className="accordion" id="pricingAccordion">
      <div className="row">
        <div className="col accordion-header-item">
          Pharmacy
        </div>
        <div className="col accordion-header-item accordion-header-right">
          Price with Coupon
      </div>
    </div>
      {props.children}
    </div>
  )
}

export default PricingAccordion
