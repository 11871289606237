export const keyPressHandler = e => {
  if (e.keyCode === 13) {
    e.preventDefault()
  }
}

export const setZipError = zipcode => {
  return `"${zipcode}" is not a valid zip code. Please enter a valid zip code and try your search again.`
}

export const getZipCodeError = zipcode => {
  let err = ""
  if (zipcode !== undefined && zipcode !== "") {
    if (zipcode.length > 5 || zipcode.length < 5) {
      // set error and exit
      err = setZipError(zipcode)
      //return true
      return err
    } else if (zipcode.match(/(\d+)/g) === null) {
      err = setZipError(zipcode)
      //return true
      return err
    } else if (zipcode.match(/(\d+)/g)[0].length !== zipcode.length) {
      err = setZipError(zipcode)
      //return true
      return err
    }
  }
  return false
}
