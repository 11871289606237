import React from "react"

const NavBar = props => {
  return (
    <nav className="navbar navbar-expand-md navbar-light bg-light">
      <div className="navbar-brand d-sm-block d-md-none">{props.title}</div>
      <div className="navbar-collapse" id="medbar">
        <ul className="navbar-nav mr-auto">{props.children}</ul>
      </div>
    </nav>
  )
}

export default NavBar
