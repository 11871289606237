import React from "react"
import { connect } from "react-redux"
import { updateZipcode, updatePricingError } from "../actions/searchActions"
import { keyPressHandler, getZipCodeError } from "../helpers/searchHelpers"

const ZipResultSearch = props => {
  const checkZip = () => {
    let err = getZipCodeError(props.zipcode)
    if (!err) {
      // update the search
      props.updatePricingError(false)
      props.updateSearch("zip", props.zipcode)
    } else {
      // update the error
      props.updatePricingError(err)
    }
  }

  return (
    <form className="form-inline zip-code-form">
      <p className="zipcode-instructions text-center">
        Enter a zip code below to find pricing at pharmacies near you
      </p>
      <div className="form-group mx-sm-3 mb-2">
        <input
          type="text"
          className="form-control"
          id="zip-search"
          placeholder="Enter Zip Code"
          onKeyDown={e => keyPressHandler(e)}
          onChange={e => props.updateZipcode(e.target.value.trim())}
          value={props.zipcode}
        />
      </div>
      <button
        type="button"
        className="btn btn-success mb-2"
        onClick={() => checkZip(props.zipcode)}
      >
        Search
      </button>
    </form>
  )
}

const mapStateToProps = state => ({
  zipcode: state.zipcode
})

export default connect(
  mapStateToProps,
  { updateZipcode, updatePricingError }
)(ZipResultSearch)
